import React from 'react'
import Layout from 'layouts/Default'
import HeaderSection from 'components/Section/headerSection'

const NotFoundPage = (props) => (
  <Layout {...props}>
    <HeaderSection
      pb={3}
      header
      padding
      headline="Page not found"
      tagline="The page you are looking for cannot be found. Please check the Url
          and try again."
    />
  </Layout>
)

export default NotFoundPage
